import { Dispatch, useCallback, useEffect, useState } from "react";

export type LocalStorageKey =
	| "scene-layout"
	| "library-layout"
	| "selected-organization"
	| "notifications-lastread"
	| "notifications-readlist"
	| "refresh-token"
	| "plan-preselect"
	| "currency-preselect"
	| "payment-method"
	| "currency"
	| "panels-state"
	| "organization-data"
	| "selected-plan"
	| "current-BIM-endpoint"
	| "default-scene-layout"
	| "collapse-sidebar"
	| "login-attepmts";

function useLocalStorage<T>(
	key: LocalStorageKey,
	initialValue: T,
): [T, Dispatch<T>] {
	const getItem = () => {
		let newValue;
		try {
			newValue = JSON.parse(window.localStorage.getItem(key) ?? "");
		} catch (e) {
			newValue = initialValue;
		}
		return newValue || initialValue;
	};

	const [value, setValue] = useState(getItem);

	const setItem = (newValue: T) => {
		setValue(newValue);
		window.localStorage.setItem(key, JSON.stringify(newValue));
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getItem, []);

	const handleStorage = useCallback(
		(event: StorageEvent) => {
			if (event.key === key && event.newValue !== value) {
				setValue(event.newValue || initialValue);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[value],
	);

	useEffect(() => {
		window.addEventListener("storage", handleStorage);
		return () => window.removeEventListener("storage", handleStorage);
	}, [handleStorage]);

	return [value, setItem];
}

export default useLocalStorage;
