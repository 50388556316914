import React from "react";
import styled from "styled-components/macro";
import { Header } from "../../components";

interface MainProps {
	padding?: number;
}

const Main: React.FC<MainProps> = ({ children, padding = 20 }) => {
	return (
		<>
			<Header />
			<StyledMain padding={padding}>{children}</StyledMain>
		</>
	);
};

const StyledMain = styled.main`
	position: relative;
	background-color: ${({ theme }) => theme.colors.grey200};
	padding: ${(props: MainProps) => props.padding}px;
	height: 100%;
	flex: auto;
`;

export default Main;
