export const ext = (filename: string) =>
	filename.split(".").pop()?.toLowerCase() ?? "";

const filenameIsFormat = (filename: string, format: string) =>
	ext(filename) === format;

export const imageAccept = ["png", "jpeg", "jpg", "tiff", "tif", "tga", "bmp"];
export const objectAccept = [
	"obj",
	"dae",
	"ifc",
	"fbx",
	"glb",
	/* "c4d", */
	"abc",
	/* "usd",
	"usdz",
	"usda",
	"usdc", */
];
export const materialAccept = ["mtl"];
export const materialAcceptRef = [
	"jpg",
	"jpeg",
	"png" /* "tiff", "psd", "bmp", "exr", "tga" */,
];

const isAccepted = (filename: string, accepted: string[]) =>
	accepted.includes(ext(filename));
export const isImage = (filename: string) => isAccepted(filename, imageAccept);
// export const isSound = (filename: string) => isAccepted(filename, soundAccept);
export const isObject3D = (filename: string) =>
	isAccepted(filename, objectAccept);
export const isMaterial = (filename: string) =>
	isAccepted(filename, materialAccept);

export const isBIN = (filename: string) => filenameIsFormat(filename, "bin");
